import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import PList from "../components/PackingList";
import FullWidthImage from "../components/FullWidthImage";


// eslint-disable-next-line
export const PackingListPageTemplate = ({
  image,
 list,
}) => {
  const sectStyle =   {backgroundImage:`url(${getImage(image)})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundColor: "#2c2c2c"}
  return (
<div>
    {/* <FullWidthImage img={heroImage} height={ "90vh"} subheading={"Packing List"}/> */}

    <div className="section section--gradient" style={sectStyle}>
        <div className="container"  >
            <div className="columns">
                <div className="column is-12">
                    <div className="content ">
                        <div className="padding_bot blueSec">
                            <div className="content">
                                <div className="columns is-multiline ">
                                    <div className="column blueSec">
                                        <div className="card ">
                                            <div className="cardcontainer blueSec">
                                                <h1>Packing List</h1>
                                                
                                                <PList items={list}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>
    
  );
};

PackingListPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  list: PropTypes.array,
};

const PackingList = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <PackingListPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        list={frontmatter.list}
      />
    </Layout>
  );
};

PackingList.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PackingList;

export const pageQuery = graphql`
    query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "packing-list" } }) {
      frontmatter {
        title
        list
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;


          