import * as React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";


const FeatureGrid = ({ items }) => {
  // Declare a const variable within the component

return (
  <div>
    {items.map((item, index) => (
      <p key={index}>{item}</p>
    ))}
    </div>
);
};


FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.string,

  ),
};

export default FeatureGrid;


{/* <section className="section">
          <div className="has-text-centered">
            <div
              style={{
                width: "240px",
                display: "inline-block",
              }}
            >
              <PreviewCompatibleImage imageInfo={item} />
            </div>
          </div>
          <p>{item.text}</p>
        </section> */}